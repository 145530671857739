import React from 'react';
import { Navbar, Nav, Button } from 'react-bootstrap';
import { Link, animateScroll as scroll } from 'react-scroll';

const scrollToTOP = () => {
  scroll.scrollToTop();
};

const NavBar = () => (
  <Navbar
    collapseOnSelect="true"
    fixed="top"
    sticky="top"
    expand="sm"
    bg="dark"
    variant="dark"
  >
    <Navbar.Brand>
      <Button variant="outline-light" onClick={scrollToTOP}>
        <img
          alt="logo"
          src="https://nimruth.s3.amazonaws.com/Icon.png"
          width="20"
          height="20"
          className="d-inline-block align-top"
        />
      </Button>
    </Navbar.Brand>
    <Navbar.Toggle aria-controls="responsive-navbar-nav" />
    <Navbar.Collapse id="responsive-navbar-nav">
      <Nav className="mr-auto">
        <Nav.Link>
          <Link
            to="carousel"
            activeClass="active"
            spy
            smooth
            offset={-70}
            duration={500}
          >
            Home
          </Link>
        </Nav.Link>
        <Nav.Link>
          <Link
            to="welcome"
            activeClass="active"
            spy
            smooth
            offset={-70}
            duration={500}
          >
            Welcome
          </Link>
        </Nav.Link>
        <Nav.Link>
          <Link
            activeClass="active"
            to="RSVP"
            spy
            smooth
            offset={-70}
            duration={500}
          >
            RSVP
          </Link>
        </Nav.Link>
        <Nav.Link>
          <Link
            activeClass="active"
            to="Sumerian"
            spy
            smooth
            offset={-70}
            duration={500}
          >
            Prakasam
          </Link>
        </Nav.Link>
        <Nav.Link>
          <Link
            activeClass="active"
            to="ThreeDObject"
            spy
            smooth
            offset={-70}
            duration={500}
          >
            Wedding Card
          </Link>
        </Nav.Link>
      </Nav>
    </Navbar.Collapse>
  </Navbar>
);

export default NavBar;
