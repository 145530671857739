/* eslint-disable react/prop-types */
/* eslint-disable react/no-unused-state */
/* eslint-disable no-console */
import React, { Component } from 'react';
import {
  // eslint-disable-next-line indent
  Button,
  Form,
  Col,
} from 'react-bootstrap';
import './style.css';
import { API } from 'aws-amplify';
import { withAlert } from 'react-alert';

const uuidv1 = require('uuid/v1');

class Rsvp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fname: '',
      lname: '',
      emailID: '',
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({ value: event.target.value });
  }

  handleSubmit(event) {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    const { alert } = this.props;
    event.preventDefault();
    const apiName = 'RsvpApi';
    const path = '/rsvps';
    const { fname } = this.state;
    const { lname } = this.state;
    const { emailID } = this.state;
    const newRsvp = {
      body: {
        ID: uuidv1(),
        FirstName: fname,
        LastName: lname,
        EmailID: emailID,
      },
    };
    API.post(apiName, path, newRsvp)
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.log(error.response);
      });
    alert.success(
      `Thank you for your conformation ${fname} ${lname} We will see you at the wedding`,
    );
  }

  render() {
    return (
      <section className="section-rsvp">
        <Form onSubmit={this.handleSubmit}>
          <h1 className="header-alignment">Please RSVP here</h1>
          <Form.Label>First Name</Form.Label>
          <Form.Row>
            <Col>
              <Form.Control
                required
                style={{ textAlign: 'center' }}
                // value={this.state.firstName}
                placeholder="First name"
                onChange={(e) => this.setState({ fname: e.target.value })}
              />
            </Col>
          </Form.Row>
          <Form.Label>Surname Name</Form.Label>
          <Form.Row>
            <Col>
              <Form.Control
                required
                style={{ textAlign: 'center' }}
                // value={this.state.lastName}
                placeholder="Surname Name"
                onChange={(e) => this.setState({ lname: e.target.value })}
              />
            </Col>
          </Form.Row>
          <Form.Group controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              required
              style={{ textAlign: 'center' }}
              type="email"
              // value={this.state.emailID}
              placeholder="Enter email"
              onChange={(e) => this.setState({ emailID: e.target.value })}
            />
          </Form.Group>
          <Button
            className="center-align"
            variant="outline-light"
            type="submit"
            size="lg"
          >
            Submit
          </Button>
        </Form>
      </section>
    );
  }
}

export default withAlert()(Rsvp);
