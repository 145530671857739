/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable max-len */
import React from 'react';
import ReactDOM from 'react-dom';
import {
  Alert,
  Card,
  Accordion,
  ButtonToolbar,
  Button,
  Modal,
} from 'react-bootstrap';
import './style.css';

function MyVerticallyCenteredModal(props) {
  return ReactDOM.createPortal(
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-vcenter modal-text">
          Instructions to interact with J.S.Prakasam
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="modal-text">
        <p>
          Press and hold the screen to talk and release for answer
          <br />
          <br />
          <br />
          If the scene fails to load, please refresh the page.
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onHide}>Close</Button>
      </Modal.Footer>
    </Modal>,
    document.getElementById('modal'),
  );
}

function AccordionShow() {
  const [modalShow, setModalShow] = React.useState(false);
  return (
    <Accordion>
      <Card>
        <Accordion.Toggle as={Card.Header} eventKey="0">
          <Alert variant="info">Instructions</Alert>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="0">
          <Card.Body>
            <ButtonToolbar>
              <Button variant="primary" onClick={() => setModalShow(true)}>
                View Instructions
              </Button>
              <MyVerticallyCenteredModal
                show={modalShow}
                onHide={() => setModalShow(false)}
              />
            </ButtonToolbar>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
}

export default AccordionShow;
