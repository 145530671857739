import { Carousel, Container } from 'react-bootstrap';
import React from 'react';
import CarouselImageOne from '../../assets/img/CarouselImages/Image1.jpg';
import CarouselImageTwo from '../../assets/img/CarouselImages/Image2.jpg';
import CarouselImageThree from '../../assets/img/CarouselImages/Image3.jpg';
import CarouselImageFour from '../../assets/img/CarouselImages/Image4.jpg';
import CarouselImageFive from '../../assets/img/CarouselImages/Image5.jpg';
import CarouselImageSix from '../../assets/img/CarouselImages/Image6.jpg';
import CarouselImageSeven from '../../assets/img/CarouselImages/Image7.jpg';
import CarouselImageEight from '../../assets/img/CarouselImages/Image8.jpg';
import './style.css';

const CustomCarousel = () => (
  <Container fluid className="container-full-screen">
    <section className="section-carousel">
      <Carousel>
        <Carousel.Item>
          <Container fluid>
            <img
              className="img-width"
              src={CarouselImageOne}
              alt="First slide"
            />
            <Carousel.Caption>
              <h3>Rings</h3>
            </Carousel.Caption>
          </Container>
        </Carousel.Item>
        <Carousel.Item>
          <Container fluid>
            <img
              className="img-width"
              src={CarouselImageTwo}
              alt="Third slide"
            />
            <Carousel.Caption>
              <h3>The lovely pair</h3>
            </Carousel.Caption>
          </Container>
        </Carousel.Item>
        <Carousel.Item>
          <Container fluid>
            <img
              className="img-width"
              src={CarouselImageThree}
              alt="Third slide"
            />
            <Carousel.Caption>
              <h3>Amruth Pavan</h3>
            </Carousel.Caption>
          </Container>
        </Carousel.Item>
        <Carousel.Item>
          <Container fluid>
            <img
              className="img-width"
              src={CarouselImageFour}
              alt="Fourth Side"
              fluid="true"
            />
            <Carousel.Caption>
              <h3>Nikitha Gayatri</h3>
            </Carousel.Caption>
          </Container>
        </Carousel.Item>
        <Carousel.Item>
          <Container fluid>
            <img
              className="img-width"
              src={CarouselImageFive}
              alt="Fourth Side"
              fluid="true"
            />
          </Container>
        </Carousel.Item>
        <Carousel.Item>
          <Container fluid>
            <img
              className="img-width"
              src={CarouselImageSix}
              alt="Fourth Side"
              fluid="true"
            />
          </Container>
        </Carousel.Item>
        <Carousel.Item>
          <Container fluid>
            <img
              className="img-width"
              src={CarouselImageSeven}
              alt="Fourth Side"
              fluid="true"
            />
          </Container>
        </Carousel.Item>
        <Carousel.Item>
          <Container fluid>
            <img
              className="img-width"
              src={CarouselImageEight}
              alt="Fourth Side"
              fluid="true"
            />
          </Container>
        </Carousel.Item>
      </Carousel>
    </section>
  </Container>
);

export default CustomCarousel;
