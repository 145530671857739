import React from 'react';
import NavBar from './NavBar/NavBar';
import Carousel from './Carousel/Carousel';
import RSVP from './Rsvp/Rsvp';
import Sumerian from './Sumerian/Sumerian';
import ThreeDObject from './3DObject/3DObject';
import Welcome from './Welcome/Welcome';
import './style.css';

const App = () => (
  <div>
    <div>
      <NavBar />
      <section id="carousel">
        <Carousel />
      </section>
      <section id="welcome">
        <Welcome />
      </section>
      <section id="RSVP">
        <RSVP />
      </section>
      <section id="Sumerian">
        <Sumerian />
      </section>
      <section id="ThreeDObject">
        <ThreeDObject />
      </section>
    </div>
  </div>
);

export default App;
