/* eslint-disable react/button-has-type */
import React from 'react';
import CardImage from '../../assets/img/Card.png';
import CardUSDZ from '../../assets/ar/Card.usdz';
import CardGLB from '../../assets/ar/Card.glb';
import './model-viewer.css';

const ThreeDObject = () => (
  <section>
    <model-viewer
      poster={CardImage}
      src={CardGLB}
      alt={CardImage}
      ios-src={CardUSDZ}
      ar
      auto-rotate
      camera-controls
      magic-leap
      unstable-webxr
      background-color="black"
    >
      <button
        slot="ar-button"
        style={{
          backgroundColor: 'white',
          borderRadius: '4px',
          border: 'none',
          position: 'absolute',
          top: '16px',
          right: '16px',
        }}
      >
        Activate AR
      </button>
    </model-viewer>
  </section>
);

export default ThreeDObject;
