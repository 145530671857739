/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import { SumerianScene } from 'aws-amplify-react';
import Amplify from 'aws-amplify';
import awsExports from '../../aws-exports';
import Accordion from '../Accordion/Accordion';

Amplify.configure(awsExports);
class Sumerian extends Component {
  render() {
    return (
      <div>
        <Accordion />
        <div style={{ height: '100vh' }}>
          <SumerianScene sceneName="prakasam" />
        </div>
      </div>
    );
  }
}

export default Sumerian;
