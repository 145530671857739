// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:17de2170-93c9-4fed-89bc-47157d5f784f",
    "aws_cognito_region": "us-east-1",
    "oauth": {},
    "XR": {
        "scenes": {
            "prakasam": {
                "sceneConfig": {
                    "sceneId": "aa5b0ad413864373ae2326d2a6697699.scene",
                    "region": "us-east-1",
                    "projectName": "Drafts",
                    "url": "https://sumerian.us-east-1.amazonaws.com/20180801/projects/Drafts/release/authTokens?sceneId=aa5b0ad413864373ae2326d2a6697699.scene"
                }
            }
        }
    },
    "aws_content_delivery_bucket": "nimruth2019-20191012162215-hostingbucket-production",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d2z2ehvoq6hhy3.cloudfront.net",
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "attendeesTable-production",
            "region": "us-east-1"
        }
    ],
    "aws_cloud_logic_custom": [
        {
            "name": "RsvpApi",
            "endpoint": "https://81bdpc59rd.execute-api.us-east-1.amazonaws.com/production",
            "region": "us-east-1"
        }
    ]
};


export default awsmobile;
